import { FREEDOM_CRM_API, FREEDOM_CRM_SITE_ID } from "../constants";

document.getElementById('contactForm').addEventListener('submit', async (e) => {
  e.preventDefault();

  const form = e.target;
  const messageDiv = document.getElementById('message');
  const submitButton = form.querySelector('button[type="submit"]');

  // Disable the submit button and show loading state
  submitButton.disabled = true;
  submitButton.innerHTML = 'Subscribing...';

  try {
    const response = await fetch(`${FREEDOM_CRM_API}/new-contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: form.email.value,
        siteId: FREEDOM_CRM_SITE_ID
      })
    });

    const data = await response.json();

    messageDiv.classList.remove('hidden', 'bg-red-100', 'text-red-700', 'bg-green-100', 'text-green-700');

    if (response.ok) {
      messageDiv.classList.add('bg-green-100', 'text-green-700');
      messageDiv.textContent = 'Thank you for subscribing!';
      form.reset();
    } else {
      messageDiv.classList.add('bg-red-100', 'text-red-700');
      messageDiv.textContent = data.error || 'An error occurred. Please try again.';
    }
  } catch (error) {
    messageDiv.classList.remove('hidden', 'bg-green-100', 'text-green-700');
    messageDiv.classList.add('bg-red-100', 'text-red-700');
    messageDiv.textContent = 'Network error. Please try again.';
  } finally {
    messageDiv.classList.remove('hidden');
    submitButton.disabled = false;
    submitButton.innerHTML = 'Subscribe';
  }
});

